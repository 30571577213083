<template>
  <div>
    <b-card title="Sales Report">
      <b-row class="ml-auto">
        <b-col md="6">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="6">
                  <div>
                    
                    <b-form-group label="From Date" label-for="From Date">
                      <flat-pickr v-model="fromDate" class="form-control" 
                       v-validate="{ date_format: 'dd/MM/yyyy', date_between:['01/01/1990',toDate] }"/>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="6">
                  <div>
                    <b-form-group label="To Date" label-for="To Date">
                      <flat-pickr v-model="toDate" class="form-control" />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        
        <!-- filter -->
        <b-col md="3" class="my-2">
       
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
        
        </b-col>

        <b-col md="1" class="my-2">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              :variant= "items.length > 0 ? 'primary' : 'secondary'"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              
                <b-dropdown-item v-if="items.length > 0" @click="exportExcelAction">
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
            </b-dropdown>
          </div>
        </b-col>

        <b-col md="12" variant="danger">
            <!-- handle error -->
            <b-alert
              v-height-fade.appear
              fade
              variant="danger"
              :show="isEmptyData"
            >
            
              <div class="alert-body">
                <ul >
                  <li>
                    There are no records to show for this range
                  </li>
                </ul>
              </div>
            </b-alert>
          </b-col>
      </b-row>
      <b-table
        ref="admint"
        id="AdminsTable"
        striped
        sticky-header
        hover
        responsive
        show-empty
        :busy.sync="isBusy"
        class="position-relative"
        :items="items"
        :fields="fields"
      >
      

        <!-- admin financialDetailsMarginPercentge -->
        <template #cell(financialDetailsMarginPercentge)="data">
          {{ data.value }} %
        </template>
        <!-- admin financialDetailsMarginPercentge -->
        <template #cell(netProfitNPPercentage)="data">
          {{ data.value }} %
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="AdminsTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  
  <script>
import { MonthPickerInput } from 'vue-month-picker'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    MonthPickerInput,
    flatPickr,
  },
  data() {
    return {
      dateOptionByYear: [],
      dateByYear: null,
      dateMonth: null,
      newMomentMonthDate: null,
      dateWeakly: null,
      dateDaily: null,
      totalsaleYear: null,
      fromDate: null,
      toDate: null,
      totalsaleQur: null,

      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100, 200, 300, 400, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      isBusy: false,
      sortBy: 'order_code',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      btnclick: true,
      today: new Date(),
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fildes
      fields: [
        {
          key: 'id',
          label: 'id',
        },
        {
          key: 'Timestamp',
          label: 'Timestamp',
        },
        {
          key: 'code',
          label: 'code',
        },
        {
          key: 'customerName',
          label: 'customer Name',
          class: 'th-spaces-update',
        },
        {
          key: 'Dest',
          label: 'Dest',
          class: 'th-spaces-update',
        },
        {
          key: 'sku',
          label: 'sku',
          class: 'th-spaces-update',
        },
        {
          key: 'phoneDescription',
          label: 'Phone Description',
          class: 'th-spaces-update',
        },
        {
          key: 'salesService',
          label: 'Sales Service',
          class: 'th-spaces-update',
        },
        {
          key: 'productStatus',
          label: 'Product Status',
          class: 'th-spaces-update',
        },
        {
          key: 'Seller',
          label: 'Seller',
          class: 'th-spaces-update',
        },
        {
          key: 'trxType',
          label: 'Trx Type',
          class: 'th-spaces-update',
        },
        {
          key: 'settledStatus',
          label: 'Settled Status',
          class: 'th-spaces-update',
        },
        {
          key: 'status',
          label: 'Order Status',
          class: 'th-spaces-update',
        },
        {
          key: 'aWBNo',
          label: 'AWB No',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsPymtGate',
          label: 'PymtGate',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsPymtChannel',
          label: 'Pymt Channel',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsPPForEachQuanity',
          label: 'PP For Each Quanity',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsQuantity',
          label: 'Quantity',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsPP',
          label: 'PP',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsShippingPrice',
          label: 'Shipping Price',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsPurchaseFees',
          label: 'Purchase Fees',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsTotalPrice',
          label: 'Total Price',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsSellerCost',
          label: 'Seller Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsMarginEGP',
          label: 'Margin EGP',
          class: 'th-spaces-update',
        },
        {
          key: 'financialDetailsMarginPercentge',
          label: 'Margin %',
          class: 'th-spaces-update',
        },
        {
          key: 'COGSChannelBankFees',
          label: 'COGS Channel Bank Fees',
          class: 'th-spaces-update',
        },
        {
          key: 'COGSVatChaneel',
          label: 'COGS Vat Chaneel',
          class: 'th-spaces-update',
        },
        {
          key: 'COGSShippingCost',
          label: 'COGS Shipping Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'COGSPacking',
          label: 'COGS Packing',
          class: 'th-spaces-update',
        },
        {
          key: 'COGSTotalCost',
          label: 'COGS Total Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'netProfitMMCommisson',
          label: 'Net Profit MM Commisson',
          class: 'th-spaces-update',
        },
        {
          key: 'netProfitVat',
          label: 'Net Profit Vat',
          class: 'th-spaces-update',
        },
        {
          key: 'netProfitNPAmount',
          label: 'Net Profit NP Amount',
          class: 'th-spaces-update',
        },
        {
          key: 'netProfitNPPercentage',
          label: 'Net Profit NP %',
          class: 'th-spaces-update',
        },
      ],
      export:false,
      dailyDate : null,
      items: [],
      exportItems: [],
      isEmptyData:true,
    }
  },
  created() {
    this.dailyDate = `${this.today.getFullYear()}-${this.today.getMonth()+1}-${this.today.getDate()}`
    this.fromDate = this.dailyDate
    this.toDate = this.dailyDate

    this.fetchData()
    this.authPermissions = this.$store.state.authPermissions
  },
  computed: {
    // sortable in table by add (sortable: true) in field of table fields
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    // compute row of pagination
    rows() {
      return this.totalRows
    },
  },
  methods: {
   
    
    exportExcelAction() {
      const date = this.today.getFullYear()+'-'+(this.today.getMonth()+1)+'-'+this.today.getDate() + '-'+
      this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
    
      const promise = axios.post('reports/sales-report/export/data', this.sendData, {responseType: 'blob'})
      return promise
        .then((result) => {
          
          console.log(result.data)
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',  'SalesReport-' + date + '.xlsx');
          document.body.appendChild(link);
          link.click();

          
          this.$swal({
              position: "center",
              icon: "success",
              title: "downloaded success",
              showConfirmButton: false,
              timer: 1500,
            });
      
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
      

      
    },
    // fetch data of admins
    fetchData() {
      this.isEmptyData = false
      this.sendData = {
        range: {
          from: this.fromDate,
          to: this.toDate,
        }
      }
      this.showDismissibleAlert = false
      var url = null
      url ='reports/sales-report/' + this.perPage.toString() +'?page=' + this.currentPage

      const promise = axios.post(url, this.sendData)
      return promise
        .then((result) => {
      

            const data = result.data.data
            this.totalRows = data.meta.total
  
            // this.items.length = Math.ceil(this.totalRows/this.perPage)
            this.items.length = 0
  
            this.items = data.data
            this.exportItems = this.items
  
            this.$refs.admint.refresh()

            if(this.items == 0){
              this.isEmptyData = true
            }
            return this.items
            
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    
    // fetch data of sort result

    showDate(date) {
      this.dateMonth = date
    },
    changedType() {
      this.dateByYear = null
      this.dateMonth = null
      this.newMomentMonthDate = null
      this.dateWeakly = null
      this.dateDaily = null
      this.totalsaleQur = null
      this.totalsaleYear = null
      this.fromDate = null
      this.toDate = null
    },
    // fetch data of search result
    dateDiff() {
      const today = new Date()
      const oldday = new Date('1 January 2020')
      var ynew = today.getFullYear()
      var mnew = today.getMonth()
      var dnew = today.getDate()
      var yold = oldday.getFullYear()
      var mold = oldday.getMonth()
      var dold = oldday.getDate()
      var diff = ynew - yold
      if (mold > mnew) diff--
      else {
        if (mold == mnew) {
          if (dold > dnew) diff--
        }
      }
      this.dateByYear = new Date().getFullYear().toString()

      for (var i = 0; i <= diff; i++) {
        this.dateOptionByYear.push((parseInt(this.dateByYear) - i).toString())
      }

      return diff
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].avatar
      }
    },
    // print function
    printEle() {
      window.print()
    },
    // generate pdf function
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].name,
          this.items[index].email,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['NAME', 'Email', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  
  <style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  
  <style lang="css" >
  .b-table-sticky-header{
    max-height: 92vh;
  }
.th-spaces-update {
  white-space: nowrap;
  text-align: center;
  direction: rtl;
}
.month-picker__container,
.month-picker--default {
  background-color: #fff;
}
[dir] .month-picker-input[data-v-201d773d] {
  height: 38px !important;
  width: 100% !important;
}
.month-picker {
  font-weight: 200;
  box-sizing: border-box;
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: sans-serif;
  overflow: hidden;
  justify-content: space-evenly;
  align-content: space-around;
}
.dountRevenu {
  height: 75% !important;
}
.month-picker-input-container[data-v-201d773d] {
  width: 100% !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
  